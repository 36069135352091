*
{
    margin: 0;
    padding: 0;
}

:root {
    /* --body-padding: .5em;
    --section-padding: 1.4em;
    --prgph-margin-top: 1em; */
    --card-img-maxwidth: 22em;
    --card-background-clr: #5e5e5e;
  }


html{
    background: #1e1a20;
}

html,body{
    font-family: 'Montserrat', sans-serif;
    color: #ffffff;

}

#loading-screen {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 1;
 	transition: 1s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* html,
body
{
    overflow: hidden;
} */

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;

}
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #deebff;
    border-radius: 10px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b9d5ff;
    }

.sri-section
{
    /* display: flex; */
    /* align-items: center; */
    height: 100vh;
    
    position:relative ;    
    /* margin-top: 8rem;  */
   
}


.sri-stroke{
    -webkit-text-stroke: 1px black;  
    color: white;  
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
} 
.sri-opacity-bg{
    /* Black fallback color */
    /* background-color: rgb(0,0,0);  */
    /* Black w/opacity */
    /* background-color: rgba(0,0,0, 0.35);  */
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 10%, rgba(0, 0, 0, 0.37) 90%, rgba(0, 0, 0, 0) 100%);
    padding: 10px;
}

/* section:nth-child(odd)
{
    justify-content: flex-end;
} */



.paragraphs{
    font-size: 1.18em;

}

.fnt-rights{
    font-size: 0.67em;
}

.sub-headers{
    font-weight: 700;
    
}

.sub-margint{
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.sub-marginb{    
    margin-bottom: 1rem;
}

.sri-text-cennter{
    text-align: center;
}

/* header */

/* .nav-cus-v{  
    

} */

ul{
    padding: 0;
    margin: 0;
    list-style: none;
}



.nav-cus-v ul{
    position: relative;
    z-index: 4;
    text-align: right;
    margin: -6px -20px 0 0;
}

.nav-cus-v li{
    position: relative;
    overflow: hidden;
    line-height: 2;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition:  all .2s ease-in-out;
    -o-transition: all .2s ease-in-out ;
    transition:  all .2s ease-in-out;
    color: #ffffff;

}

.nav-cus-v li:hover{
     /* transition: 0.5s;
    -webkit-transform: translate(0, 6px); */
    transform: scale(1.2);
    padding-right: 5px;
    /* font-size: 16px; */
    cursor: pointer;
/* font-weight: 800;
letter-spacing: 2px; */
}

.nav-cus-v li a{
    text-decoration: none;
    color: #ffffff;

}

/* .font-sides{
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
} */

.nav-cus-v .menu-item a{
    /* color: #fff; */
    overflow: hidden;
    padding: 5px;
    pointer-events: auto;
}



.nav-sli li > *{
    position: relative;
    display: inline-block;
}

.header-cus{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:12% ;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 23px 30px 0;
    -webkit-transition: all .5s linear 0s;
    transition: all .5s linear 0s;
    /* pointer-events: none; */
}


.header-cus.header-cus-menu{
 height: 100%;
 width: 100%;
 /* Black w/opacity */
 /* transition: height .5s; */
 /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    /* pointer-events: none; */
}


.logo{
    display: inline-block;
    line-height: 120%;
    position: relative;
    z-index: 8;
    
}

.font-logo{
    font-size: 24px;
    font-weight: 800;
}





/* .font-content{

} */



.menuIcon-cus{
	/* position:fixed; */
	width:60px;
	height:60px;
	/* bottom:40px;
	right:40px; */
	background-color:#ffffff;
	color:#000000;
	border-radius:50px;
	text-align:center;
	/* box-shadow: 2px 2px 3px #999; */
}

.menu-item-mb{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.menu-anch{
    color: #ffffff;
    text-decoration: none;
    font-size: 24px ;
    
}

/* .menu-cus-main{
    width: 50px;
} */

/* footer */

.footer-cus{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 0 30px 25px;
    pointer-events: none;
}

.menu-social{
    margin: 0 0 0 -5px;
}

.menu-social li{
    position: relative;
    overflow: hidden;
    line-height: 1;
}

.footer-cus a{
    /* color: #fff; */
    display: block;
    pointer-events: auto;
    padding: 5px;
}

/* floating button */

.float-cus{
	/* position:fixed; */
	width:60px;
	height:60px;
	/* bottom:40px;
	right:40px; */
	background-color:#ffffff;
	color:#000000;
	border-radius:50px;
	text-align:center;
	/* box-shadow: 2px 2px 3px #999; */
}

.my-float{
	margin-top:24px;
}

/* container */

/* .container-sri{
    margin-left: 8rem;
    margin-right: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;

} */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 329px) {


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 2.5em;
        font-weight: 800;
        line-height: 1;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 312px;
        /* margin-top: ; */
    }
    
    .row-sri{
        margin-top: 3rem;
    }

    .sri-section
    {

        margin-bottom: 20rem;
    }

    .container-card-sri .box{
        width: 143px;
        height: 196px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.6rem;
    }

    .card-text-h{
        font-size: 0.8rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height:15% ;
        padding: 16px 30px 16px;
    }

    

    .footer-cus
    {
        background-color: #000000ed;
        padding: 0 16px 16px;
    }

}



/* Extra small devices (xxm phones, 600px and down) */
@media only screen and (min-width: 330px) {



    .main-headers{
    
        /* color: #ffffff; */
        font-size: 2.5em;
        font-weight: 800;
        line-height: 1;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 336px;
        /* margin-top: ; */
    }
    
    .row-sri{
        margin-top: 3rem;
    }

    .sri-section
    {

        margin-bottom: 10rem;
    }

    .container-card-sri .box{
        width: 143px;
        height: 196px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.6rem;
    }

    .card-text-h{
        font-size: 0.8rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height:14% ;
        padding: 16px 30px 16px;
    }
    
    .footer-cus
    {
        background-color: #000000ed;

        padding: 0 16px 16px;
    }

}


/* Extra small devices (xxm phones, 600px and down) */
@media only screen and (min-width: 380px) {


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 2.7em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 342px;
        /* margin-top: ; */
    }
    
    .row-sri{
        margin-top: 3rem;
    }


    .sri-section
    {

        margin-bottom: 8rem;
    }

    .container-card-sri .box{
        width: 143px;
        height: 196px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.6rem;
    }

    .card-text-h{
        font-size: 0.8rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height:12% ;
        padding: 16px 30px 16px;
    }

    .footer-cus
    {
        background-color: #000000ed;

        padding: 0 16px 16px;
    }
    

}

/* Extra small devices (xm phones, 400px and down) */
@media only screen and (min-width: 420px) {


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 2.7em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 402px;
        /* margin-top: ; */
    }
    
    .row-sri{
        margin-top: 3rem;
    }


    .sri-section
    {

        margin-bottom: 3rem;
    }

    .container-card-sri .box{
        width: 143px;
        height: 196px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.6rem;
    }

    .card-text-h{
        font-size: 0.8rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height: 12%;
        padding: 16px 30px 16px;
    }

    .footer-cus
    {
        background-color: #000000ed;

        padding: 0 16px 16px;
    }

}

/* Normal Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 576px) {



    .main-headers{
    
        /* color: #ffffff; */
        font-size: 3em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }


    .container-sri{
        max-width: 442px;
        /* margin-top: ; */
    }
    
    .row-sri{
        margin-top: 0rem;
    }

    .sri-section
    {

        margin-bottom: 3rem;
    }

    .container-card-sri .box{
        width: 143px;
        height: 196px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.6rem;
    }

    .card-text-h{
        font-size: 0.8rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height:12% ;
        padding: 16px 30px 16px;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
    


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 3.2em;
        font-weight: 800;
        line-height: 1.3;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 504px;
    }

    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 168px;
        height: 240px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.8rem;
    }

    .card-text-h{
        font-size: 1rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height:12% ;
        padding: 30px 30px 16px;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 842px) {
    


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 3.4em;
        font-weight: 800;
        line-height: 1.3;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 542px;
    }

    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 168px;
        height: 240px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.8rem;
    }

    .card-text-h{
        font-size: 1rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #000000ed;
        height:14% ;
        padding: 23px 30px 0;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 3.5em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 832px;
    }
    
    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 168px;
        height: 240px;
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .card-text-p{
        font-size: 0.8rem;
    }

    .card-text-h{
        font-size: 1rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #00000000;
        height:12% ;
        padding: 23px 30px 0;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {



    .main-headers{
    
        /* color: #ffffff; */
        font-size: 3.5em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 1032px;
    }

    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 240px;
        height: 240px;
        margin-top: 36px;
        margin-bottom: 36px;
    }

    .card-text-p{
        font-size: 0.8rem;
    }

    .card-text-h{
        font-size: 1rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 14px;
    }

    .header-cus{
        background-color: #00000000;
        height:12% ;
        padding: 23px 30px 0;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1320px) {



  .main-headers{
  
      /* color: #ffffff; */
      font-size: 3.6em;
      font-weight: 800;
      line-height: 1.2;
      /* padding-left: 10%;
      padding-right: 10%; */
  }

  .container-sri{
      max-width: 1122px;
  }

  .sri-section
  {

      margin-bottom: 0rem;
  }

  .container-card-sri .box{
      width: 240px;
      height: 240px;
      margin-top: 76px;
      margin-bottom: 36px;
  }

  .card-text-p{
      font-size: 0.8rem;
  }

  .card-text-h{
      font-size: 1rem;
  }

  .container-card-sri .box .boxbody .content div{
      padding: 14px;
  }

  .header-cus{
      background-color: #00000000;
      height:12% ;
      padding: 23px 30px 0;
  }

  .footer-cus
  {
      background-color: #00000000;
      padding: 0 30px 25px;
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {



    .main-headers{
    
        /* color: #ffffff; */
        font-size: 4em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 1124px;
    }

    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 210px;
        height: 210px;
        margin-top: 66px;
        margin-bottom: 66px;
    }

    .card-text-p{
        font-size: 0.8rem;
    }

    .card-text-h{
        font-size: 1rem;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 16px;
    }

    .header-cus{
        background-color: #00000000;
        height:12% ;
        padding: 23px 30px 0;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }
}


/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1600px) {
 


    .main-headers{
    
        /* color: #ffffff; */
        font-size: 4.2em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 1332px;
    }

    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 285px;
        height: 285px;
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 20px;
    }

    .header-cus{
        background-color: #00000000;
        height:12% ;
        padding: 23px 30px 0;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }

}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1800px) {


    
    .main-headers{
    
        /* color: #ffffff; */
        font-size: 4.2em;
        font-weight: 800;
        line-height: 1.2;
        /* padding-left: 10%;
        padding-right: 10%; */
    }

    .container-sri{
        max-width: 1502px;
    }

    .sri-section
    {

        margin-bottom: 0rem;
    }

    .container-card-sri .box{
        width: 300px;
        height: 300px;
        margin-top: 74px;
        margin-bottom: 74px;
    }

    .container-card-sri .box .boxbody .content div{
        padding: 20px;
    }

    .header-cus{
        background-color: #00000000;
        padding: 23px 30px 0;
        height:12% ;
    }

    .footer-cus
    {
        background-color: #00000000;
        padding: 0 30px 25px;
    }
}

 /* nav menue */
 

.container-card-sri {
    
    transform-style: preserve-3d
}

.container-card-sri .box {
    position: relative;

    margin-right: auto;
    margin-left: auto;

    /* margin: 20px; */
    transform-style: preserve-3d;
    perspective: 1000px;
    cursor: pointer
}

.container-card-sri .box .boxbody {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.9s ease
}

.container-card-sri .box .boxbody .imgContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d
}

.container-card-sri .box .boxbody .imgContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.container-card-sri .box .boxbody .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateY(180deg)
}

.container-card-sri .box:hover .boxbody {
    transform: rotateY(180deg)
}

.container-card-sri .box .boxbody .content div {
    transform-style: preserve-3d;
    background: linear-gradient(45deg, #0095ff, #000000);
    transform: translateZ(100px)
}

.container-card-sri .box .boxbody .content div h3 {
    letter-spacing: 1px;
    /* line-height: 0.02; */
    
}

.subcard-title{
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.07em;
}

.subcard-title-mb{
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.07em;
}

.mb-mb-cus{
    padding-bottom: 5em;
}
/* .team-sec{
   
    test

} */
/* social */
.social {
    /* position:absolute; */
    /* top: -134%;
    left:4%; */
    /* transform:translate(-50%, -50%); */
    /* display:flex; */
    margin:0;
    padding:0;
  }
  
  .social li {
    list-style:none;
    margin: 32px 0px;
  }
  
  .social li a .cfa {
    font-size: 16px;
    color: #262626;
    line-height:18px;
    transition: .5s;
    /* padding-right: 32px; */
  }
  
  .social li a span {
    padding:0;
    margin:0;
    font-size: 0.8rem;
    position:absolute;
    top: 14px;
    left: 42px;
    color: #000000;
    letter-spacing: 1px;
    transition: .5s;
  }
  
  .social li a {
    text-decoration: none;
    display:absolute;
    display:block;
    width: 38px;
    height:32px;
    background: #deebff;
    text-align:left;
    padding-left: 12px;
    transform: rotate(-30deg) skew(25deg) translate(0,0);
    transition:.5s;
    box-shadow: -20px 20px 10px rgba(0,0,0,.5);
  }
  .social li a:before {
    content: '';
    position: absolute;
    top:10px;
    left:-20px;
    height:100%;
    width:20px;
    background: #37393c;
    transform: .5s;
    transform: rotate(0deg) skewY(-45deg);
  }
  .social li a:after {
    content: '';
    position: absolute;
    bottom:-20px;
    left:-10px;
    height:20px;
    width:100%;
    background: #78879c;
    transform: .5s;
    transform: rotate(0deg) skewX(-45deg);
  }

  .float-email .social li a:before {
    content: '';
    position: absolute;
    top:10px;
    left:-20px;
    height:100%;
    width:20px;
    background: #78879c ;
    transform: .5s;
    transform: rotate(0deg) skewY(-45deg);
  }
  .float-email .social li a:after {
    content: '';
    position: absolute;
    bottom:-20px;
    left:-10px;
    height:20px;
    width:100%;
    background: #37393c;
    transform: .5s;
    transform: rotate(0deg) skewX(-45deg);
  }
  
  .social li a:hover {
    transform: rotate(-30deg) skew(25deg) translate(20px,-15px);
    box-shadow: -50px 50px 50px rgba(0,0,0,.5);
  }
  
  .social li:hover .cfa {
    color:#fff;
  }
  
  .social li:hover span {
    color:#fff;
  }
  
  .social li:hover:nth-child(1) a{
    background: #3b5998;
  }
  .social li:hover:nth-child(1) a:before{
    background: #365492;
  }
  .social li:hover:nth-child(1) a:after{
    background: #4a69ad;
  }
  
  .social li:hover:nth-child(2) a{
    background: #00aced;
  }
  .social li:hover:nth-child(2) a:before{
    background: #097aa5;
  }
  .social li:hover:nth-child(2) a:after{
    background: #53b9e0;
  }
  
  .social li:hover:nth-child(3) a{
    background: #dd4b39;
  }
  .social li:hover:nth-child(3) a:before{
    background: #b33a2b;
  }
  .social li:hover:nth-child(3) a:after{
    background: #e66a5a;
  }
  
  .social li:hover:nth-child(4) a{
    background: #e4405f;
  }
  .social li:hover:nth-child(4) a:before{
    background: #d81c3f;
  }
  .social li:hover:nth-child(4) a:after{
    background: #e46880;
  }

  .float-email{
      float: right;
  }



  /* for mobile buttons */


  .socialMb {
    /* position:absolute; */
    /* top: -134%;
    left:4%; */
    /* transform:translate(-50%, -50%); */
    display:flex;
    /* margin:0; */
    /* padding:0; */
    margin-right: auto;
    margin-left: auto;
  }
  
  .socialMb li {
    list-style:none;
    /* margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px; */
    margin: 16px 16px;
  }
  
  .socialMb li a .cfa {
    font-size: 16px;
    color: #262626;
    line-height:18px;
    transition: .5s;
    /* padding-right: 32px; */
  }
  
  .socialMb li a span {
    padding:0;
    margin:0;
    font-size: 0.8rem;
    position:absolute;
    top: 14px;
    left: 42px;
    color: #000000;
    letter-spacing: 1px;
    transition: .5s;
    /* only for mobile */
    display: none; 
  }
  
  .socialMb li a {
    text-decoration: none;
    display:absolute;
    display:block;
    width: 38px;
    height:32px;
    background: #deebff;
    text-align:left;
    padding-left: 12px;
    transform: rotate(-30deg) skew(25deg) translate(0,0);
    transition:.5s;
    box-shadow: -20px 20px 10px rgba(0,0,0,.5);
  }
  .socialMb li a:before {
    content: '';
    position: absolute;
    top:10px;
    left:-20px;
    height:100%;
    width:20px;
    background: #37393c;
    transform: .5s;
    transform: rotate(0deg) skewY(-45deg);
  }
  .socialMb li a:after {
    content: '';
    position: absolute;
    bottom:-20px;
    left:-10px;
    height:20px;
    width:100%;
    background: #78879c;
    transform: .5s;
    transform: rotate(0deg) skewX(-45deg);
  }

  .float-email .socialMb li a:before {
    content: '';
    position: absolute;
    top:10px;
    left:-20px;
    height:100%;
    width:20px;
    background: #78879c ;
    transform: .5s;
    transform: rotate(0deg) skewY(-45deg);
  }
  .float-email .socialMb li a:after {
    content: '';
    position: absolute;
    bottom:-20px;
    left:-10px;
    height:20px;
    width:100%;
    background: #37393c;
    transform: .5s;
    transform: rotate(0deg) skewX(-45deg);
  }
  
  .socialMb li a:hover {
    transform: rotate(-30deg) skew(25deg) translate(20px,-15px);
    box-shadow: -50px 50px 50px rgba(0,0,0,.5);
  }
  
  .socialMb li:hover .cfa {
    color:#fff;
  }
  
  .socialMb li:hover span {
    color:#fff;
  }
  
  .socialMb li:hover:nth-child(1) a{
    background: #3b5998;
  }
  .socialMb li:hover:nth-child(1) a:before{
    background: #365492;
  }
  .socialMb li:hover:nth-child(1) a:after{
    background: #4a69ad;
  }
  
  .socialMb li:hover:nth-child(2) a{
    background: #00aced;
  }
  .socialMb li:hover:nth-child(2) a:before{
    background: #097aa5;
  }
  .socialMb li:hover:nth-child(2) a:after{
    background: #53b9e0;
  }
  
  .socialMb li:hover:nth-child(3) a{
    background: #dd4b39;
  }
  .socialMb li:hover:nth-child(3) a:before{
    background: #b33a2b;
  }
  .socialMb li:hover:nth-child(3) a:after{
    background: #e66a5a;
  }
  
  .socialMb li:hover:nth-child(4) a{
    background: #e4405f;
  }
  .socialMb li:hover:nth-child(4) a:before{
    background: #d81c3f;
  }
  .socialMb li:hover:nth-child(4) a:after{
    background: #e46880;
  }

  /* .scrolldiv{
    position: relative;
    
  } */

  .icon-scroll,
  .icon-scroll:before {
      position: absolute;
      left: 48.8%;
     
      cursor: pointer;
  }
  .icon-scroll {
      width: 30px;
      height: 50px;
      /* margin-left: -20px; */
      /* bottom: 64px; */
      bottom: 26px;
      margin-top: -35px;
      border: 2px solid #fff;
      border-radius: 25px
  }
  @media (max-width: 767px) {
      .icon-scroll {
          position: relative
      }
  }
  .icon-scroll:before {
      content: '';
      width: 8px;
      height: 8px;
      background: #fff;
      margin-left: -4px;
      top: 8px;
      border-radius: 4px;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: scroll
  }
  @keyframes scroll {
      0% {
          opacity: 1
      }
      100% {
          opacity: 0;
          transform: translateY(26px)
      }
  }


  .cursor {

    perspective: 1000px;
    transform-origin: bottom right;
    position: absolute;
    left: 50%;
    top: 50px;
    width: 30px;
    height: 30px;
    margin-top: 100px;
    z-index: 20;
    pointer-events: none;
    animation: translate_l2b 2s infinite;
  }
  
  @keyframes translate_l2b {
    0% {  
      transform: translate(-3px, 4px);
    }
     20% {  
      transform: translate(0px, 0px);
    }
    80% {  
      transform: translate(-3px, 4px);
    }
  
    96% {  
      transform: translate(-3px, 4px);
    }
    100% {
        transform: translate(-3px, 4px);
    }
  
  }
  
  .cursor:after {
    content: "";
    display: block;
    position: absolute;
    left: -14px;
    top: -10px;
    width: 36px;
    height: 36px;
    border: 2px solid rgb(190, 164, 201, 1);
    border-radius: 50%;
    z-index: 4;
    animation: wave_l2b 2s infinite;
  }
  
  @keyframes wave_l2b {
    0% {  
      transform: scale(1);
      opacity: .7;
    }
    5% {  
      transform: scale(1.2);
      opacity: 0;
    }
    6% {  
      transform: scale(0);
      opacity: 0;
    }
    44% {  
      transform: scale(0);
      opacity: 0;
    }
    45% {  
      transform: scale(0);
      opacity: 1;
    }
    60% {  
      transform: scale(0);
    }
    100% {  
      transform: scale(1);
      opacity: .7;
    }
  }
  
  .cursor:before {
    content: "";
    display: block;
    position: absolute;
      left: -45px;
      top: -45px;
    width: 80px;
    height: 80px;
    border: 5px solid rgb(255, 255, 255);
    border-radius: 50%;
    z-index: 4;
    animation: wave2_l2b 2s infinite;
  }
  
  @keyframes wave2_l2b {
    0% {  
      transform: scale(1);
      opacity: 1;
    }
    25% {  
      transform: scale(1.2);
      opacity: 0;
    }
    26% {  
      transform: scale(0);
      opacity: 0;
    }
    50% {  
      transform: scale(0);
      opacity: 1;
    }
    90% {  
      transform: scale(0);
    }
    100% {  
      transform: scale(1);
      opacity: 1;
    }
  }
  
  #click_l2b {
      position: relative;
      animation: click_l2b 2s infinite;
      z-index: 5;
      stroke-width: 2;
      stroke-dasharray: 10 5;
      fill: rgba(120, 67, 144, 0.43);
  }
  
  
  @keyframes click_l2b {
    0% {  
      transform: skew(0deg) translateX(0px) scaleY(.9);
    }
    50% {
      transform: skew(-6deg) translateX(3px) scaleY(1);
    }
    100% {  
      transform: skew(0deg) translateX(0px) scaleY(.9);
    }
  }
  

 /* list */

 ol.gradient-list > li::before, ol.gradient-list > li {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
  }
  
  /*** STYLE ***/
  /* *,
  *:before,
  *:after {
    box-sizing: border-box;
  } */
  
  /* html,
  body {
    margin: 0;
    padding: 0;
  } */
  
  /* body {
    background-color: #fafafa;
    color: #1d1f20;
    font-family: "Raleway", sans-serif;
  }
   */
  main {
    display: block;
    margin: 0 auto;
    max-width: 40rem;
    padding: 1rem;
  }
  
  ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
  }
  ol.gradient-list > li {
    background: #303336;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
  }
  ol.gradient-list > li::before, ol.gradient-list > li::after {
    background: linear-gradient(135deg, #0095ff  0%, #ffffff 100%);
    border-radius: 1rem 1rem 0 1rem;
    content: "";
    height: 3rem;
    left: -1rem;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    width: 3rem;
  }
  ol.gradient-list > li::before {
    align-items: flex-end;
    content: counter(gradient-counter);
    color: #1d1f20;
    display: flex;
    font: 900 1.5em/1 "Montserrat";
    justify-content: flex-end;
    padding: 0.125em 0.25em;
    z-index: 1;
  }
  /* ol.gradient-list > li:nth-child(10n+1):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
  }
  ol.gradient-list > li:nth-child(10n+2):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
  }
  ol.gradient-list > li:nth-child(10n+3):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
  }
  ol.gradient-list > li:nth-child(10n+4):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
  }
  ol.gradient-list > li:nth-child(10n+5):before {
    background: linear-gradient(135deg, #a2ed56 0%, #fddc32 100%);
  }
  ol.gradient-list > li:nth-child(10n+6):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
  }
  ol.gradient-list > li:nth-child(10n+7):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
  }
  ol.gradient-list > li:nth-child(10n+8):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
  }
  ol.gradient-list > li:nth-child(10n+9):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
  }
  ol.gradient-list > li:nth-child(10n+10):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0) 0%, rgba(253, 220, 50, 0) 100%);
  } */
  ol.gradient-list > li + li {
    margin-top: 2rem;
  }

  .sec-two {
    /* margin-top: 15em; */
    padding: 0.5em var(--section-padding);
    grid-column: 1/3;
  }
  .sec-two h2 {
    margin-top: 0.1em;
  }

  .sec-two h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin-bottom: 0.08rem;
  }
  .sec-two p {
    margin-top: var(--prgph-margin-top);
    line-height: 1.4em;
  }
  .sec-two .samples-ui {
    margin-top: 1em;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, 1fr);
  }
  .sec-two .samples-ui .sample {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 1em;
    border-radius: 0.5em;
  }
  .sec-two .samples-ui .sample-one {
    grid-column: 1/3;
    background: var(--card-background-clr);
    position: relative;
  }
  .sec-two .samples-ui .sample-one:hover .overlay-card {
    opacity: 1;
  }
  .sec-two .samples-ui .sample-two {
    grid-column: 1/3;
    background: var(--card-background-clr);
    position: relative;
  }
  .sec-two .samples-ui .sample-two:hover .overlay-card {
    opacity: 1;
  }
  .sec-two .samples-ui .sample-three {
    grid-column: 1/3;
    background: var(--card-background-clr);
    position: relative;
  }
  .sec-two .samples-ui .sample-three:hover .overlay-card {
    opacity: 1;
  }
  .sec-two .samples-ui .card-container p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sec-two .samples-ui .card-image {
    margin-top: 1em;
  }
  .sec-two .samples-ui .card-image img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center center;
       object-position: center center;
  }
  .sec-two .samples-ui .overlay-card {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    background-color: rgba(0, 0, 0, 0.6588235294);
  }
  .sec-two .samples-ui .overlay-card .overlay-txt {
    width: 4em;
    color: white;
    text-decoration: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
  }
  .sec-two .samples-ui .designs-sample {
    grid-column: 1/3;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    background: var(--card-background-clr);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 1em;
    border-radius: 0.5em;
  }
  .sec-two .samples-ui .designs-sample .card-imagesxx img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center center;
       object-position: center center;
  }

  @media screen and (max-width: 620px) {

  }
  @media screen and (min-width: 621px) {

  }
  @media only screen and (min-width: 900px) {

    .sec-two {
      margin-top: 2em;
      padding: 0.5em var(--section-padding) 0.5em 0;
      grid-column: 2/3;
      grid-row: 2;
    }
    .sec-two .sec-two-container {
      /* margin-top: 40vh; */
    }
    .sec-two h2 {
      font-size: 3rem;
      line-height: 3rem;
    }
    .sec-two .samples-ui .sample-one {
      grid-column: 1/2;
    }
    .sec-two .samples-ui .sample-two {
      grid-column: 1/2;
    }
    .sec-two .samples-ui .sample-three {
      grid-column: 2/3;
    }
    .sec-two .samples-ui .designs-sample {
      grid-column: 1/3;
    }
    .sec-two .samples-ui .card-image img {
      height: 11em;
    }
    
  }
  @media only screen and (min-width: 1200px) {
    :root {
      /* --body-padding: .5em;
      --section-padding: 8.4em;
      --prgph-margin-top: 1em; */
      --card-img-maxwidth: 11.5em;
      --card-background-clr: #5e5e5e;
    }
  
    
    .sec-two {
      margin-top: 2em;
      padding: 0.5em var(--section-padding) 0.5em 0;
      grid-column: 2/3;
      grid-row: 2;
    }
    .sec-two .sec-two-container {
      /* margin-top: 40vh; */
    }
    .sec-two h2 {
      font-size: 3rem;
      line-height: 3rem;
    }
    .sec-two .samples-ui .card-image img {
      height: 11em;
    }
    
  }/*# sourceMappingURL=style.css.map */